import React from 'react'

const Exchanges = () => {
  return (
    <div id="excanges">
      exchanges
    </div>
  )
}

export default Exchanges
