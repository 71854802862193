import React from 'react'

const CryptoDetails = () => {
  return (
    <div id="cryptodetails">
      CryptoDetails
    </div>
  )
}

export default CryptoDetails
